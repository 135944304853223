import './Motto.scss';
import Buttons from '../../Organisms/Buttons/Buttons';

type MottoProps = {
  mottoHeader: string;
}

const Motto = ({ mottoHeader }: MottoProps) => {
  return (
    <section id='motto'>
        <div className="motto__container">
            {/* <img src={images.MottoImage} alt="imgs" /> */}
            <div className="motto__containerText">
                <h2>{mottoHeader}</h2>
                <p>Join over 200M active Skibble users worldwide.</p>
              
                <Buttons />
            </div>

            <div className="motto__containerTextMobile">
                  <h2>Eat.</h2>
                  <h2>Connect.</h2>
                  <h2>Share.</h2>
                  <h2>With Skibble.</h2>
                  <div>
                    <p>Join over 200M active</p>  
                    <p>Skibble users worldwide.</p>
                  </div>

                  <Buttons 
                    mottoButtonStyles styles={{ display: 'flex', flexDirection: 'column'}} 
                    buttonStyles={{ marginInline: "auto" }}
                  />
                </div>
        </div>
    </section>
  )
}

export default Motto