import { useState } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import './DiscoveredProblems.scss';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ButtonBase, Popover } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

function GetIcon(){
  const [isOpen, setIsOpen] = useState(false)
  return (
    <>
      <ButtonBase onClick={() => setIsOpen(true)}>
        <MoreVertIcon />
      </ButtonBase>

      <Popover
        anchorReference='anchorPosition'
        open={isOpen} 
        onClick={() => setIsOpen(false)}
        anchorPosition={{
          top: 450,
          left: 1400
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      {/* The content of the Popover. */}
      <div className='discoveredProblems__Popover'>
        <ButtonBase>
          <VisibilityIcon  className='popover__icons'/>
        </ButtonBase>
        <span>View details</span>
      </div>

      <div className='discoveredProblems__Popover'>
        <ButtonBase>
          <TaskAltIcon  className='popover__icons'/>
        </ButtonBase>
        <span>Mark as complete</span>
      </div>

      <div className='discoveredProblems__Popover'>
        <ButtonBase>
          <DeleteForeverIcon
            className='popover__icons popover__delIcon' 
          />
        </ButtonBase>
        <span>Delete mail</span>
      </div>
    </Popover>
    </>
  )
}

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'firstName', headerName: 'NAME', width: 130 },
  { field: 'lastName', headerName: 'USERNAME', width: 130 },
  {
    field: 'email',
    headerName: 'EMAIL',
    type: 'string',
    width: 170,
  },
  {
    field: 'date',
    headerName: 'DATE',
    type: 'date',
    width: 130,
  },
  {
    field: 'message',
    headerName: 'MESSAGE',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 300,
    // valueGetter: (params: GridValueGetterParams) =>
    //   `${params.row.firstName || ''} ${params.row.lastName || ''}`,
  },
  { 
    field: 'actions',
    // headerName: 'Actions',
    description: 'Row Actions',
    width: 100, 
    renderCell: () => (
      GetIcon()
    )
    // getActions: () => [
    //   <GridActionsCellItem 
    //     icon={<MoreVertIcon />}
    //     label="More"
    //     showInMenu

    //   />
    // ]
  }
];

// rows are going to be dynamic...

const rows = [
  { id: 1, lastName: 'Snow', email: "example@gmail.com", firstName: 'Jon', date: "Apr 23, 2021", message: "Alert Heap/Traffic Error",  },
  { id: 2, lastName: 'Lannister', email: "example@gmail.com", firstName: 'Cersei', date: "Apr 23, 2021", message: "Alert Heap/Traffic Error", },
  { id: 3, lastName: 'Lannister', email: "example@gmail.com", firstName: 'Jaime', date: "Apr 23, 2021", message: "Alert Heap/Traffic Error", },
  { id: 4, lastName: 'Stark', email: "example@gmail.com", firstName: 'Arya', date: "Apr 25, 2021", message: "Yoma Error", },
  { id: 5, lastName: 'Targaryen', email: "example@gmail.com", firstName: 'Daenerys', date: "Apr 23, 2021", message: "Alert Heap/Traffic Error", },
  { id: 6, lastName: 'Melisandre', email: "example@gmail.com", firstName: "Yoma", date: "Apr 23, 2021", message: "Alert Heap/Traffic Error", },
  { id: 7, lastName: 'Clifford', email: "example@gmail.com", firstName: 'Ferrara', date: "Apr 30, 2021", message: "Alert Heap/Traffic Error", },
  { id: 8, lastName: 'Frances', email: "example@gmail.com", firstName: 'Rossini', date: "Apr 23, 2021", message: "Alert Heap/Traffic Error", },
  { id: 9, lastName: 'Roxie', email: "example@gmail.com", firstName: 'Harvey', date: "Apr 23, 2021", message: "Alert Heap/Traffic Error", },
];

// const rowWithActionButtons = rows.push()


const DiscoveredProblems = () => {
  return (
    <div style={{ height: 400, width: '100%' }} className="discoveredProblems">
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        checkboxSelection
      />
    </div>
  )
}

export default DiscoveredProblems