import AboutHero from "../../Components/AboutHero/AboutHero";
import Copyright from "../../Components/Copyright/Copyright";
import Footer from "../../Components/Footer/Footer";
import Motto from "../../sections/About/Motto/Motto";
import AboutDetails from "../../Organisms/AboutDetails/AboutDetails";

import { PageProps } from "../SkibbleLandingPage";
import TopOfPage from "../../utils/TopOfPage";

import "./AboutPage.scss";

const AboutPage = ({ children }: PageProps) => {
  TopOfPage();

  return (
    <section id="aboutPage">
      <div className="skibble-about-nav-con">{children}</div>

      <AboutHero />

      <AboutDetails />

      <Motto />

      <Footer />
      <Copyright />
    </section>
  );
};

export default AboutPage;
