import Copyright from "../Components/Copyright/Copyright";
import Faq from "../Components/Faq/Faq";
import Footer from "../Components/Footer/Footer";
import Testimonial from "../Components/Testimonial/Testimonial";
import Business from "../sections/Home/Business/Business";
import Capture from "../sections/Home/Capture/Capture";
import Community from "../sections/Home/Community/Community";
import Discover from "../sections/Home/Discover/Discover";
import Hero from "../sections/Home/Hero/Hero";
import Meets from "../sections/Home/Meets/Meets";
import Motto from "../sections/Home/Motto/Motto";
import Recipe from "../sections/Home/Recipe/Recipe";
import Subscribe from "../sections/Home/Subscribe/Subscribe";
import Unique from "../sections/Home/Unique/Unique";

import TopOfPage from "../utils/TopOfPage";

import "./SkibbleHomePage.scss";

type PageProps = {
  children?: React.ReactElement;
};

const SkibbleHomePage = ({ children }: PageProps) => {
  TopOfPage();

  return (
    <div className="skibble-home">
      <div className="skibble-home-nav-con">{children}</div>
      <Hero />
      <Meets />
      <Capture />
      <Discover />
      <div className="skibble-home-unique-con">
        <Unique />
        <Recipe />
      </div>
      <Community />
      <Business />
      <Testimonial />
      <Motto />
      <div className="skibble-home-width">
        <Faq />
      </div>
      <Subscribe />
      <Footer />
      <Copyright />
    </div>
  );
};

export default SkibbleHomePage;
