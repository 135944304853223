import { Box, Tab, Tabs, Typography } from '@mui/material';
import React from 'react'
import './EmailTable.scss';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import DiscoveredProblems from '../../Tables/DiscoveredProblems/DiscoveredProblems';


type TabPanelProps = {
  children?: React.ReactNode;
  index: number;
  value: number;
}

// API CALLS WILL BE MADE HERE.

const EmailTable = () => {
  const [value, setValue] = React.useState(0);

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className='emailTable'>
      <h2>Emails</h2>

      <div className="emailTable__tabs">
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Discoverd Problems" {...a11yProps(0)} sx={{borderBottomColor: "red"}}/>
          <Tab label="Featured requests" {...a11yProps(1)} />
          <Tab label="Privacy policy" {...a11yProps(2)} />
          <Tab label="App suggestions" {...a11yProps(3)} />
          <Tab label="Help requests" {...a11yProps(4)} />
          <Tab label="Contact us" {...a11yProps(5)} />
        </Tabs>
      </div>

      <div className="email__search">
        <input type="text" placeholder='Search for email, Customer...'/>

        <button>
          <FilterAltOutlinedIcon className='emailTable__buttonIcons'/>
          Filter
        </button>

        <button>
          <OpenInNewOutlinedIcon className='emailTable__buttonIcons'/>
          Export CSV
        </button>
      </div>

      <TabPanel value={value} index={0}>
        <DiscoveredProblems />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <DiscoveredProblems />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <DiscoveredProblems />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <DiscoveredProblems />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <DiscoveredProblems />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <DiscoveredProblems />
      </TabPanel>

    </div>
  )
}

export default EmailTable