import images from "./images";

export const links = [
  {
    id: 1,
    img: images.LinkedIn,
    socialLink: "https://www.linkedin.com/company/skibbleapp/",
  },
  {
    id: 2,
    img: images.Facebook,
    socialLink: "https://web.facebook.com/theskibbleapp/",
  },
  // {
  //   id: 3,
  //   img: images.Twitter,
  //   socialLink: "https://www.twitter.com/SkibbleApp",
  // },
  {
    id: 4,
    img: images.Instagram,
    socialLink: "https://www.instagram.com/skibble.app",
  },
];
