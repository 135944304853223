
import CTAGreenIcon from "../../../Components/Icons/CTAGreenIcon";

import "./Hero.scss";

const Hero = () => {
  return (
    <section className="kitchen-hero__wrapper">
      <div className="kitchen-hero__container">
        <div className="kitchen-hero__left">
          <h3>
            Your All-in-One <br />
            Solution for Food <br />
            Business Management
          </h3>
          <a href={"https://app.youform.io/forms/kgkmivem"}>
            <CTAGreenIcon />
          </a>
        </div>
        <div className="kitchen-hero__right">
          Manage Menus, Track Orders, and Connect with Delivery Platforms all in
          One Place - Skibble Kitchens.
        </div>
      </div>
    </section>
  );
};

export default Hero;
