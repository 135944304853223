import "./AboutDetails.scss";

const aboutDetailsImageSet = () => {
  return ["320w", "720w"]
    .map((size) => `/images/about_details_${size}.webp ${size}`)
    .join(",");
};

const AboutDetails = () => {
  return (
    <section className="about-details__wrapper">
      <div className="about-details__text-con">
        <p>
          At Skibble, we believe that food brings people together. Our platform
          is designed to foster building meaningful and fun connections over
          food and a vibrant community of food enthusiasts who share a common
          love for culinary experiences. Whether you're a passionate foodie
          always on the hunt for new food experiences/recipes or someone who
          simply enjoys savoring delicious meals, Skibble is here to inspire and
          connect you with like-minded individuals.
        </p>
      </div>
      <div className="about-details__image-con">
        <img srcSet={aboutDetailsImageSet()} alt="skibble2.0" />
      </div>
      <div className="about-details__text-con">
        <p>
          With Skibble, you can capture and share your most memorable food
          moments, whether it's a delightful dish at a restaurant, a
          mouthwatering creation you've made yourself, or a fun gathering with
          friends. Our unique location tagging and recipe-sharing features make
          it easy for your loved ones to find the same amazing food experiences
          without any hassle. Join us on this gastronomic adventure, and let's
          celebrate the joy of food together!
        </p>
      </div>
    </section>
  );
};

export default AboutDetails;
