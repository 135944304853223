import RecipeIcon from "../../../Components/Icons/RecipeIcon";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import "./Unique.scss";

const Unique = () => {
  return (
    <section className="home-unique__wrapper">
      <div className="home-unique__container">
        <div className="home-unique__text-con">
          <h3>
            Discover new unique food <br /> experiences in your city
          </h3>
          <p>
            Are you craving new food experiences? Find those yummy hidden <br />
            food spots in your city you never knew existed before!
          </p>
        </div>
        <div className="home-unique__images-con">
          <div className="home-unique__image-con">
            <img src="/images/unique_image_1.webp" alt="food" />
          </div>
          <div className="home-unique__image-con">
            <img src="/images/unique_image_2.webp" alt="people" />
          </div>
          <div className="home-unique__image-con">
            <img src="/images/unique_image_3.webp" alt="unique-food" />
          </div>
          <div className="home-unique__dish-image-con">
            <img src="/images/unique_dish_pic.webp" alt="" />
          </div>
        </div>
        <div className="home-unique__images-con-mobile">
          <Carousel autoPlay infiniteLoop interval={4000} stopOnHover swipeable showArrows={false} showStatus={false} showThumbs={false}>
            <div className="home-unique__image-con">
              <img src="/images/unique_image_1.webp" alt="food" />
            </div>
            <div className="home-unique__image-con">
              <img src="/images/unique_image_2.webp" alt="people" />
            </div>
            <div className="home-unique__image-con">
              <img src="/images/unique_image_3.webp" alt="unique-food" />
            </div>
          </Carousel>
        </div>
        <div className="home-unique__recipe-icon">
          <RecipeIcon />
        </div>
      </div>
    </section>
  );
};

export default Unique;
