import "./Discover.scss";

const Discover = () => {
  return (
    <section className="home-discover__wrapper">
      <div className="home-discover__container">
        <div className="home-discover__image-con">
          <img src="/images/home_discover_480w.webp" alt="skibble2.0" />
        </div>
        <div className="home-discover__image-con-mobile">
          <img src="/images/home_discover_360w.webp" alt="skibble2.0" />
        </div>
        <div className="home-discover__text-con">
          <h3>
            Discover what to eat <br /> and where to eat
          </h3>
          <p>
            Don’t know what to eat or even where to eat tonight? Access
            personalized food recommendations and places all tailored to your
            food interests.
          </p>
          <div>
            <a
              href="https://apps.apple.com/app/skibble-food-social-network/id1633887491"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/images/app_store.png" alt="" />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.mcfranchostudios.skibble"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/images/playstore.png" alt="" />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Discover;
