import React from 'react'
import './AdminNav.scss';
import { images } from '../../../../constants';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const AdminNav = () => {
  return (
    <nav className='adminNav'>
        <div className="adminNav__left">
            <img src={images.Skibble} alt="skibbleLogo" />
            <h1>Admin Portal</h1>
        </div>

        <div className="adminNav__right">
            <NotificationsIcon />
            <MoreVertIcon />

            <img src={images.NavUserImg} alt="userImg" className="adminNav__rightImg" />
        </div>
    </nav>
  )
}

export default AdminNav