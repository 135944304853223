import { useState } from 'react'
import { images } from '../../constants';
import './BlogPost.scss';

type BlogPostProp = {
    loopThree?: boolean;
    loopAll?: boolean;
    blogArticle?: boolean;
    whiteBackground? : boolean;
}

const BlogPost = ({ loopThree, loopAll, blogArticle, whiteBackground}: BlogPostProp) => {
    const [blogPost] = useState([
        {
            id: 1, 
            textHeading: '5 cool features for chefs in Skibble', 
            textDescription: 'Here are 5 features you didn’t know existed on the Skibble app...',
            img: images.BlogImage
        },

        {
            id: 2, 
            textHeading: '5 cool features for chefs in Skibble', 
            textDescription: 'Here are 5 features you didn’t know existed on the Skibble app...',
            img: images.BlogImage
        },

        {
            id: 3, 
            textHeading: '5 cool features for chefs in Skibble', 
            textDescription: 'Here are 5 features you didn’t know existed on the Skibble app...',
            img: images.BlogImage,
        },
    ])
  return (
    <section id="blogPost">
        <div className="blogPost__container">
            {loopAll ? (<h1 style={{ fontSize:"39.34px", lineHeight: "46px", color: "#00BF6D"}}>Other blog posts</h1>): blogArticle ? (<h3>Other posts</h3>) : (<h1>Latest blog post</h1>)}

            {
                loopAll && (
                    <>
                        <div className="blogPost__items">
                        {blogPost.map(blogPost => (
                            <div className="blogPost__item" key={blogPost.id}> 
                                <div className="blogPost__itemImage">
                                    <img src={blogPost.img}alt="blog_img" />
                                </div>
                                <div className="blogPost__text">
                                    <h3>{blogPost.textHeading}</h3>
                                    <p>{blogPost.textDescription}</p>
                                    <button className="blogPost__readMore">Read More</button>
                                </div>
                            </div>
                        ))}
                    </div>
        
                    <div className="blogPost__items" style={{ marginTop:"30px" }}>
                        {blogPost.map(blogPost => (
                            <div className="blogPost__item" key={blogPost.id}> 
                                <div className="blogPost__itemImage">
                                    <img src={blogPost.img}alt="blog_img" />
                                </div>
                                <div className="blogPost__text">
                                    <h3>{blogPost.textHeading}</h3>
                                    <p>{blogPost.textDescription}</p>
                                    <button className="blogPost__readMore">Read More</button>
                                </div>
                            </div>
                        ))}
                    </div>
                </>
                )
            }

            {
                loopThree && (
                    <div className="blogPost__items">
                    {blogPost.map(blogPost => (
                        <div className="blogPost__item" key={blogPost.id}> 
                            <div className="blogPost__itemImage">
                                <img src={blogPost.img}alt="blog_img" />
                            </div>
                            <div className="blogPost__text">
                                <h3>{blogPost.textHeading}</h3>
                                <p>{blogPost.textDescription}</p>
                                <button className="blogPost__readMore">Read More</button>
                            </div>
                        </div>
                    ))}
                </div>
                )
            }
            
        </div>
    </section>
  )
}

export default BlogPost