import "./WaitingListConfirmation.scss";
import { images } from "../../../constants";
import CloseIcon from "../../../Components/Icons/CloseIcon";

type WaitingListProp = {
  submitted: boolean;
  isSkibble2?: boolean;
  setPropIsSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
};

const WaitingListConfirmation = ({
  submitted,
  isSkibble2,
  setPropIsSubmitted,
}: WaitingListProp) => {
  return (
    <div className="waitingListConfirmation">
      <div>
        <div
          className="waitlist__confirm-close-icon"
          onClick={() => setPropIsSubmitted(false)}
        >
          <CloseIcon />
        </div>
        <img src={images.SkibbleConfirm} alt="logo" />

        <h3>
          You're now officially <br />
          on the waitlist!
        </h3>

        {isSkibble2 && (
          <div>
            <a
              href="https://chat.whatsapp.com/CRy8SRZsl5a9FzB3bBpQ0h"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button>Join the Skibble community on Whatsapp</button>
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default WaitingListConfirmation;
