import Footer from "../../Components/Footer/Footer";
import Copyright from "../../Components/Copyright/Copyright";
import Motto from "../../sections/Home/Motto/Motto";

import TopOfPage from "../../utils/TopOfPage";
import { images } from "../../constants";

import { PageProps } from "../SkibbleLandingPage";

import "./BlogPage.scss";

const BlogPage = ({ children }: PageProps) => {
  TopOfPage();

  return (
    <section id="blogPage">
      <div
        style={{
          position: "fixed",
          top: "3em",
          background: "white",
          zIndex: 3000,
          width: "90%",
          height: "",
          left: "50%",
          transform: "translate(-50%, -50%)",
          borderRadius: "50px",
        }}
        className=""
      >
        {children}
      </div>
      {/* <BlogHero /> */}

      {/* <BlogPost loopAll/> */}
      <div className="blogPost__comingSoon">
        <img src={images.AboutComingSoon} alt="blogImg" />

        <div className="blogPost__text">
          <h3>We're still cooking!</h3>
          <p>We'll let you know when it's ready</p>
        </div>
      </div>
      <Motto />

      <Footer />
      <Copyright />
    </section>
  );
};

export default BlogPage;
