import Copyright from "../../Components/Copyright/Copyright";
import Footer from "../../Components/Footer/Footer";
import Motto from "../../sections/About/Motto/Motto";
import Cards from "../../sections/Kitchen/Cards/Cards";
import Centralized from "../../sections/Kitchen/Centralized/Centralized";
import Empower from "../../sections/Kitchen/Empower/Empower";
import Hero from "../../sections/Kitchen/Hero/Hero";
import Simplify from "../../sections/Kitchen/Simplify/Simplify";

import TopOfPage from "../../utils/TopOfPage";
import { PageProps } from "../SkibbleLandingPage";

import "./Kitchen.scss";

const KitchenPage = ({ children }: PageProps) => {
  TopOfPage();

  return (
    <main id="kitchenPage">
      <div className="skibble-kitchen-nav-con">{children}</div>
      <Hero />
      <Empower />
      <Simplify />
      <Centralized />
      <Cards />
      <Motto />

      <Footer />
      <Copyright />
    </main>
  );
};

export default KitchenPage;
