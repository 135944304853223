/* eslint-disable import/no-anonymous-default-export */
import Skibble from "../assets/skibbleLogo.png";
import SkibbleConfirm from "../assets/SkibbleConfirm.png";
import SkibbleConfirmCloseIcon from "../assets/closeIcon.png";
import BackgroundImage from "../assets/BackgroundImage.png";
import LinkedIn from "../assets/linkedIn.png";
import Instagram from "../assets/instagram.png";
import Facebook from "../assets/facebook.png";
import Twitter from "../assets/twitter.png";
import FeatureImg1 from "../assets/Feature1-img.png";
import Feature1Iphone from "../assets/Feature1-iphone.png";
import FeatureImg2 from "../assets/Feature2-img.png";
import Feature2Iphone from "../assets/Feature2-iphone.png";
import FeatureImg3 from "../assets/iphone3.png";
import Feature3Iphone from "../assets/Feature3-iphone.png";
import FeatureImg4 from "../assets/iphone4.png";
import Feature4Iphone from "../assets/Feature4-iphone.png";
import FeatureImg5 from "../assets/Feature5-img.png";
import Reply1 from "../assets/reply1.png";
import Reply2 from "../assets/reply2.png";
import PlayStore from "../assets/playStoreButton.png";
import AppStore from "../assets/appStore.png";
import MottoImage from "../assets/mottoBackground.png";
import TestimonialImg1 from "../assets/TestimonialImg1.png";
import TestimonialImg2 from "../assets/TestimonialImg2.png";
import User1 from "../assets/user1.png";
import User2 from "../assets/user2.png";
import User3 from "../assets/user3.png";
import User4 from "../assets/user4.png";
import TestimonialStar from "../assets/TestimonialStar.png";
import TestimonialQuote from "../assets/testimonialQuote.png";
import BlogImage from "../assets/blogPost.png";
import FacebookLink from "../assets/FacebookLink.png";
import TwitterLink from "../assets/TwitterLink.png";
import InstagramLink from "../assets/InstagramLink.png";
import FooterIconArrow from "../assets/FooterIcon.png";
import FooterLinkedIn from "../assets/footerLinkedIn.png";
import TransparentLogo from "../assets/TransparentLogo.png";
import AboutHero from "../assets/AboutHero.png";
import ValueImage1 from "../assets/valueImg1.png";
import ValueImage2 from "../assets/newValueImg2.png";
import ValueImage3 from "../assets/valueImg3.png";
import ValueImage4 from "../assets/valueImg4.png";
import ValueImage5 from "../assets/valueImg5.png";
import ValueImage6 from "../assets/valueImg6.png";
import ContactImage from "../assets/contactImage.png";
import BlogHeroImage from "../assets/blogHero.png";
import ContactThankYou from "../assets/ContactThankYou.png";
import BlogPostHeroImage from "../assets/BlogPostHeroImage.png";
import BlogArticleImage from "../assets/blogArticleImg.png";
import ErrorPic from "../assets/ErrorPic.png";
import TermLink from "../assets/TermsLink.png";
import AboutComingSoon from "../assets/AboutComingSoon1.png";
import NavUserImg from "../assets/navUser.png";
import EmailIcon from "../assets/AdminMail.png";
import DevIcon from "../assets/AdminDev.png";
import ExclamationIcon from "../assets/AdminExclamation.png";
import waitlistBackground from "../assets/waitlistBackground.png";
import skibbleWaitlistBackground from "../assets/skibbleWaitlistBackground.png";
import chefDashboardWaitlistBackground from "../assets/chefDashboardWaitlistBackground.png";
import skibbleWaitlistBackgroundMobile from "../assets/skibbleWaitlistBackgroundMobile.png";
import chefDashboardWaitlistBackgroundMobile from "../assets/chefDashboardWaitlistBackgroundMobile.png";

export default {
  EmailIcon,
  DevIcon,
  ExclamationIcon,
  TermLink,
  LinkedIn,
  Facebook,
  Instagram,
  Twitter,
  BackgroundImage,
  Skibble,
  SkibbleConfirm,
  SkibbleConfirmCloseIcon,
  FeatureImg1,
  Feature1Iphone,
  FeatureImg2,
  Feature2Iphone,
  FeatureImg3,
  Feature3Iphone,
  FeatureImg4,
  Feature4Iphone,
  FeatureImg5,
  Reply1,
  Reply2,
  PlayStore,
  AppStore,
  MottoImage,
  TestimonialImg1,
  TestimonialImg2,
  User1,
  User2,
  User3,
  User4,
  TestimonialStar,
  TestimonialQuote,
  BlogImage,
  FacebookLink,
  TwitterLink,
  InstagramLink,
  FooterIconArrow,
  FooterLinkedIn,
  TransparentLogo,
  AboutHero,
  ValueImage1,
  ValueImage2,
  ValueImage3,
  ValueImage4,
  ValueImage5,
  ValueImage6,
  ContactImage,
  BlogHeroImage,
  ContactThankYou,
  BlogPostHeroImage,
  BlogArticleImage,
  ErrorPic,
  AboutComingSoon,
  NavUserImg,
  waitlistBackground,
  skibbleWaitlistBackground,
  chefDashboardWaitlistBackground,
  skibbleWaitlistBackgroundMobile,
  chefDashboardWaitlistBackgroundMobile
};
