import { Drawer, Typography, IconButton } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import './NavDrawer.scss';
import { Menu } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { useLocation } from 'react-router-dom';

type NavDrawerProp = {
    greenBackground?: boolean;
    blackColor?: boolean;
}

const NavDrawer = ({ greenBackground, blackColor }: NavDrawerProp) => {
    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

    // getting the route path...
    let {pathname} = useLocation();

    // destructuring object

    // = pathname === '/blog' ? true : false;
    let navbarBackgroundColor;

    if(pathname === '/blog' || pathname === '/privacy' || pathname === `/blog/1` || pathname === '/terms'|| pathname === '/about' || pathname === '/faq' || pathname === '/kitchen') {
        navbarBackgroundColor = true;
    }else{
        navbarBackgroundColor = false;
    }
      
  return (
    <React.Fragment>
            <IconButton 
                edge="end" 
                sx={{ width: "54px", height: "54px"}} 
                aria-label="logo" 
                onClick={() => setIsDrawerOpen(!isDrawerOpen)} 
                className={true ? "iconWhite" : "iconGreen"}
            >
                {isDrawerOpen ? <CloseIcon fontSize='large' style={{ color: navbarBackgroundColor ? "white" : "#00BF6D"}}/> : <Menu fontSize='large' style={{ color: navbarBackgroundColor ? "white" : blackColor ? "black" : "#00BF6D"}}/> }
            </IconButton>
        <Drawer anchor='right' open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
            <Box p={2} width={'100vw'} textAlign='left' role='presentation' sx={{ paddingTop: "150px", height: "100vh"}} className='nav__drawerLists'>
                <Link to={'/'} style={{ textDecoration: "none"}}>
                    <Typography variant='h6' component='div' className='nav__drawerText'>Home</Typography>
                </Link>
                <Link to={'/about'} style={{ textDecoration: "none"}}>
                    <Typography variant='h6' component='div' className='nav__drawerText'>About Us</Typography>
                </Link>
                <Link to={'/kitchen'} style={{ textDecoration: "none"}}>
                    <Typography variant='h6' component='div' className='nav__drawerText'>For Food Businesses</Typography>
                </Link>
                <Link to={'/contact'} style={{ textDecoration: "none"}}>
                    <Typography variant='h6' component='div' className='nav__drawerText'>Contact Us</Typography>
                </Link>
            </Box>
        </Drawer>
    </React.Fragment>
  )
}

export default NavDrawer