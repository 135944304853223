import './NotFound.scss';
import { PageProps } from '../SkibbleLandingPage';
import { images } from '../../constants';
import Footer from '../../Components/Footer/Footer';
import Copyright from '../../Components/Copyright/Copyright';

const NotFound = ({children}: PageProps) => {
  return (
    <section id="notFound">
        <div style={{ position: 'fixed', top: '10%', background: "white", zIndex: 3000, width: "90%", height: "" , left: "50%", transform: "translate(-50%, -50%)", borderRadius: "50px", border: "1px solid lightgray" }} className="">
            {children}
        </div>
        
        <div className="notFound__container container">
            <img src={images.ErrorPic} alt="Error-404" />

            <h1>Oops!</h1>

            <p>We can't find this page you're looking for.</p>
        </div>

        <Footer />
        <Copyright />
    </section>
  )
}

export default NotFound