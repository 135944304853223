import { useState } from "react";

import QuotesIcon from "../Icons/QuotesIcon";
import { images } from "../../constants";

import "./Testimonial.scss";

const Testimonial = () => {
  const [testimonials] = useState([
    {
      id: 1,
      name: "Jane Cooper",
      text: "Skibble make your restaurant visible, get new customers and earn revenue. Showcase your cooking skills and earn money as a chef.",
      star: [1, 2, 3, 4, 5],
    },
    {
      id: 2,
      name: "Darlene Robertson",
      text: "I like the UI and the thought behind it. It just might become the next social media sensation.",
      star: [1, 2, 3, 4, 5],
    },
    {
      id: 3,
      name: "Albert Flores",
      text: "I think Skibble is a nice concept for foodies. It's easy to use, navigate. Simple design.",
      star: [1, 2, 3, 4, 5],
    },
    {
      id: 4,
      name: "Abiola Bello",
      text: "Skibble make your restaurant visible, get new customers and earn revenue. Showcase your cooking skills and earn money as a chef.",
      star: [1, 2, 3, 4, 5],
    },
  ]);
  return (
    <section id="testimonialContainer">
      <div className="testimonialList">
        {testimonials.map((testimonial) => (
          <div className="testimonialItem" key={testimonial.id}>
            <div className="testimonialItem__images">
              <QuotesIcon />
            </div>
            <div className="testimonialItem__bottomText">
              <p className="testimonialItem__name">{testimonial.name}</p>
              <p className="testimonialItem__description">{testimonial.text}</p>
            </div>
            <div className="testimonialItem__star">
              {testimonial.star?.map((str) => (
                <img key={str} src={images.TestimonialStar} alt="star" />
              ))}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Testimonial;
