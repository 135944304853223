const QuotesIcon = () => {
  return (
    <svg
      width="71"
      height="59"
      viewBox="0 0 71 59"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.4823 43.7962C26.4823 46.8218 26.254 49.3179 25.7974 51.2846C25.3408 53.1 24.0472 54.8397 21.9164 56.5038C19.9378 58.1679 17.1222 59 13.4695 59C7.99035 59 4.33762 57.6385 2.51125 54.9154C0.837085 52.041 0 48.3346 0 43.7962C0 27.4577 8.59914 12.859 25.7974 0L32.6463 7.71539C26.1018 13.4641 22.8296 19.4397 22.8296 25.6423C22.8296 27.1551 23.4384 29.8026 24.6559 33.5846C25.8735 37.3667 26.4823 40.7705 26.4823 43.7962ZM64.836 43.7962C64.836 46.8218 64.6077 49.3179 64.1511 51.2846C63.8467 53.1 62.7053 54.8397 60.7267 56.5038C58.7481 58.1679 55.9325 59 52.2797 59C46.8006 59 43.1479 57.6385 41.3215 54.9154C39.4952 52.041 38.582 48.3346 38.582 43.7962C38.582 27.4577 47.1811 12.859 64.3794 0L71 7.71539C64.6077 13.0103 61.4116 18.9859 61.4116 25.6423C61.4116 27.1551 61.9443 29.8026 63.0096 33.5846C64.2272 37.3667 64.836 40.7705 64.836 43.7962Z"
        fill="#00BF6D"
      />
    </svg>
  );
};

export default QuotesIcon;
