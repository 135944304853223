import './ContactConfirmation.scss';
import { images } from '../../constants';

const ContactConfirmation = () => {
  return (
    <div className="contact__confirmation">
         {/* <img 
          src={images.SkibbleConfirmCloseIcon} 
          alt="closeIcon" style={{ position: 'absolute', right: '50px', top: '20px', cursor: 'pointer' }} 
        /> */}
        <img src={images.ContactThankYou} alt="contact__thankYou" />
        
        <div className="contact__text">
            <h1>Thank You!</h1>
            <div>
                <p>Thank you for sharing your thoughts.</p>
                <p>We appreciate your feedback!</p>
            </div>
        </div>  
    </div>
  )
}

export default ContactConfirmation