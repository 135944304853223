import CommunityIcon from "../../../Components/Icons/CommunityIcon";
import "./Community.scss";

const Community = () => {
  return (
    <section className="home-community__wrapper">
      <div className="home-community__container">
        <div className="home-community__text-con">
          <CommunityIcon />
          <h3>
            Join vibrant food communities <br />
            and fuel your culinary journey
          </h3>
          <p>
            Start and join a food community that matches your interests and
            <br />
            easily connect with food lovers from all over the world! <br />
            Whether you’re seeking meal ideas, or looking for fellow foodies to
            <br />
            exchange tips and tricks, Skibble communities got your back!
          </p>
        </div>
        <div className="home-community__image-con">
          <img src="/images/home_community.webp" alt="" />
        </div>
      </div>
    </section>
  );
};

export default Community;
