import BlogPost from '../BlogPost/BlogPost'
import Copyright from '../Copyright/Copyright'
import Footer from '../Footer/Footer'
import Motto from '../Motto/Motto'
import './Blog.scss'
import { PageProps } from '../../Pages/SkibbleLandingPage'
import BlogPostHero from '../BlogPostHero/BlogPostHero'
import BlogArticle from '../BlogArticle/BlogArticle'

const Blog = ({ children}: PageProps) => {
  return (
    <section id="blog"> 
      <div style={{ position: 'fixed', top: '10%', background: "white", zIndex: 3000, width: "90%", height: "" , left: "50%", transform: "translate(-50%, -50%)", borderRadius: "50px", }} className="">
        {children}
      </div>
    
    <BlogPostHero />

       {/* BLOG ARTICLE */}
    <BlogArticle />

    <BlogPost loopThree blogArticle/>
    <Motto mottoHeader='Start using Skibble today' />
    <Footer />
    <Copyright />
    </section>
  )
}

export default Blog