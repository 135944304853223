import BlackArrow from "../../../Components/Icons/BlackArrow";

import "./Scroller.scss";

const data = [
  "Recipe Manager",
  "Manage Menus",
  "Track Orders",
  "Connect with Delivery Platforms",
  "Customer Chat",
  "Recipe Manager",
  "Manage Menus",
  "Track Orders",
  "Connect with Delivery Platforms",
  "Customer Chat",
];

const Card = ({ text }: { text: string }) => {
  return (
    <button className="scroller-card__button">
      <BlackArrow /> {text}
    </button>
  );
};

const Scroller = () => {
  return (
    <section className="slider-container">
      <div className="slide-track-right">
        {data.map((item, idx) => (
          <div className="slide" key={idx}>
            <Card text={item} />
          </div>
        ))}
      </div>
    </section>
  );
};

export default Scroller;
