import { useState } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";
import ClipLoader from "react-spinners/ClipLoader";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";

import Copyright from "../../Components/Copyright/Copyright";
import Footer from "../../Components/Footer/Footer";
import ContactConfirmation from "../../Components/ContactConfirmation/ContactConfirmation";

import { images } from "../../constants";
import TopOfPage from "../../utils/TopOfPage";

import "./Contact.scss";

const Contact = () => {
  TopOfPage();
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [text, setText] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const navigate = useNavigate();

  const btnDisabled = !email || !fullName || !text;

  const handleSubmit = async () => {
    if (btnDisabled) {
      return;
    }

    if (!email?.includes("@")) {
      alert("Please enter a valid email address");
      return;
    }

    setIsLoading(true);
    setError(false);

    try {
      const functions = await getFunctions();
      const contactUs = httpsCallable(functions, "contactUs");
      await contactUs({
        fullName,
        email,
        contactReason: text,
      });
      setIsSubmitted(true);
      setEmail("");
      setFullName("");
      setText("");
    } catch (error) {
      setError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const goToHomePage = () => {
    navigate("/");
  };

  return (
    <section id="contact">
      <div className="contact__image">
        <div className="contact-wrapper">
          <div className="contact__header container">
            <img
              src={images.TransparentLogo}
              alt="img"
              onClick={goToHomePage}
            />
          </div>
          <div className="contact__container container">
            {isSubmitted ? (
              <ContactConfirmation />
            ) : (
              <>
                <div className="contact__left">
                  <div>
                    <h3>
                      We’d love to <br />
                      hear from you!
                    </h3>
                    <p>
                      If you have any questions, suggestions, or feedback
                      regarding Skibble, please don't hesitate to reach out to
                      us.
                    </p>
                  </div>
                  <div className="contact__left-bottom">
                    <div>
                      <h4>Customer Support</h4>
                      <p>
                        For any assistance or support, please email our customer
                        support team at hello@skibble.app. We strive to respond
                        to all inquiries within 24 hours and ensure that your
                        concerns are addressed promptly
                      </p>
                    </div>
                    <div>
                      <h4>Partnerships and Collaborations</h4>
                      <p>
                        If you're interested in partnering with Skibble or
                        exploring collaboration opportunities, please email us
                        at hello@skibble.app. We're open to collaborations with
                        restaurants, food brands, influencers, and other
                        relevant entities.
                      </p>
                    </div>
                    <div>
                      <h4>Media and Press Inquiries</h4>
                      <p>
                        For media and press inquiries, please contact our press
                        team at hello@skibble.app. We're happy to provide
                        information, arrange interviews, and assist with any
                        media-related requests
                      </p>
                    </div>
                    <div>
                      <h4>General Inquiries</h4>
                      <p>
                        For general inquiries or any other matters, you can
                        reach us at hello@skibble.app. We value your input and
                        look forward to hearing from you.
                      </p>
                    </div>
                    <div>
                      <h4>
                        Send us a message at
                        <br />
                        hello@skibble.app
                      </h4>
                    </div>
                  </div>
                </div>

                <div className="contact__right">
                  <div id="form">
                    <h3>Send us a message</h3>
                    <TextField
                      className="input-field"
                      id="outlined-basic"
                      label="Full Name"
                      variant="outlined"
                      name="fullName"
                      onChange={(e: any) => setFullName(e.target.value)}
                    />

                    <TextField
                      className="input-field"
                      id="outlined-basic"
                      label="Email"
                      variant="outlined"
                      name="email"
                      onChange={(e: any) => setEmail(e.target.value)}
                    />
                    <TextField
                      id="outlined-basic"
                      label="How can we help you?"
                      variant="outlined"
                      multiline
                      rows={15}
                      name="comment"
                      onChange={(e: any) => setText(e.target.value)}
                    />

                    <button
                      onClick={handleSubmit}
                      disabled={isLoading || btnDisabled}
                    >
                      {isLoading ? (
                        <ClipLoader color="white" />
                      ) : (
                        <span> Send Message</span>
                      )}
                    </button>
                    {error && (
                      <h3 className="error-text">
                        An error occurred. Please try again
                      </h3>
                    )}
                  </div>
                </div>

                <div className="contact__mobile-bottom">
                  <div>
                    <h4>Customer Support</h4>
                    <p>
                      For any assistance or support, please email our customer
                      support team at hello@skibble.app. We strive to respond to
                      all inquiries within 24 hours and ensure that your
                      concerns are addressed promptly
                    </p>
                  </div>
                  <div>
                    <h4>Partnerships and Collaborations</h4>
                    <p>
                      If you're interested in partnering with Skibble or
                      exploring collaboration opportunities, please email us at
                      hello@skibble.app. We're open to collaborations with
                      restaurants, food brands, influencers, and other relevant
                      entities.
                    </p>
                  </div>
                  <div>
                    <h4>Media and Press Inquiries</h4>
                    <p>
                      For media and press inquiries, please contact our press
                      team at hello@skibble.app. We're happy to provide
                      information, arrange interviews, and assist with any
                      media-related requests
                    </p>
                  </div>
                  <div>
                    <h4>General Inquiries</h4>
                    <p>
                      For general inquiries or any other matters, you can reach
                      us at hello@skibble.app. We value your input and look
                      forward to hearing from you.
                    </p>
                  </div>
                  <div>
                    <h4>
                      Send us a message at
                      <br />
                      hello@skibble.app
                    </h4>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <Footer />
      <Copyright />
    </section>
  );
};

export default Contact;
