import "./Hero.scss";

const heroImageSet = () => {
  return ["360w", "480w", "720w"]
    .map((size) => `/images/hero_cooking_together_${size}.webp ${size}`)
    .join(",");
};

const Hero = () => {
  return (
    <section className="home-hero__wrapper">
      <div className="home-hero__container">
        <div className="home-hero__text-con">
          <h1>
            Discover, connect, <br /> and share memorable <br /> food
            experiences with <br /> Skibble 2.0
          </h1>
          <div>
            <a
              href="https://apps.apple.com/app/skibble-food-social-network/id1633887491"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/images/app_store.png" alt="" />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.mcfranchostudios.skibble"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/images/playstore.png" alt="" />
            </a>
          </div>
        </div>
        <div className="home-hero__image-con">
          <img srcSet={heroImageSet()} alt="skibble2.0" />
        </div>
      </div>
    </section>
  );
};

export default Hero;
