import Scroller from "../Scroller/Scroller";

import "./Empower.scss";

const Empower = () => {
  return (
    <section className="kitchen-empower__wrapper">
      <div className="kitchen-empower__container">
        <div className="kitchen-empower__top">
          <div className="kitchen-empower__image-con">
            <img src="/images/kitchen_empower.webp" alt="kitchen_empower" />
          </div>
        </div>
      </div>
      <div className="kitchen-empower__gradient"></div>
      <div className="kitchen-empower__bottom">
        <Scroller />
      </div>
    </section>
  );
};

export default Empower;
