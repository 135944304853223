const Dashboard = () => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Vector"
        d="M25.5556 12.7778V2.55556C25.5556 1.83148 25.8009 1.22411 26.2916 0.733444C26.7805 0.244481 27.387 0 28.1111 0H43.4444C44.1685 0 44.775 0.244481 45.264 0.733444C45.7547 1.22411 46 1.83148 46 2.55556V12.7778C46 13.5019 45.7547 14.1084 45.264 14.5973C44.775 15.088 44.1685 15.3333 43.4444 15.3333H28.1111C27.387 15.3333 26.7805 15.088 26.2916 14.5973C25.8009 14.1084 25.5556 13.5019 25.5556 12.7778ZM0 23V2.55556C0 1.83148 0.245334 1.22411 0.736 0.733444C1.22496 0.244481 1.83148 0 2.55556 0H17.8889C18.613 0 19.2203 0.244481 19.711 0.733444C20.2 1.22411 20.4444 1.83148 20.4444 2.55556V23C20.4444 23.7241 20.2 24.3306 19.711 24.8196C19.2203 25.3102 18.613 25.5556 17.8889 25.5556H2.55556C1.83148 25.5556 1.22496 25.3102 0.736 24.8196C0.245334 24.3306 0 23.7241 0 23ZM25.5556 43.4444V23C25.5556 22.2759 25.8009 21.6686 26.2916 21.1779C26.7805 20.6889 27.387 20.4444 28.1111 20.4444H43.4444C44.1685 20.4444 44.775 20.6889 45.264 21.1779C45.7547 21.6686 46 22.2759 46 23V43.4444C46 44.1685 45.7547 44.775 45.264 45.264C44.775 45.7547 44.1685 46 43.4444 46H28.1111C27.387 46 26.7805 45.7547 26.2916 45.264C25.8009 44.775 25.5556 44.1685 25.5556 43.4444ZM0 43.4444V33.2222C0 32.4981 0.245334 31.8908 0.736 31.4001C1.22496 30.9111 1.83148 30.6667 2.55556 30.6667H17.8889C18.613 30.6667 19.2203 30.9111 19.711 31.4001C20.2 31.8908 20.4444 32.4981 20.4444 33.2222V43.4444C20.4444 44.1685 20.2 44.775 19.711 45.264C19.2203 45.7547 18.613 46 17.8889 46H2.55556C1.83148 46 1.22496 45.7547 0.736 45.264C0.245334 44.775 0 44.1685 0 43.4444ZM5.11111 20.4444H15.3333V5.11111H5.11111V20.4444ZM30.6667 40.8889H40.8889V25.5556H30.6667V40.8889ZM30.6667 10.2222H40.8889V5.11111H30.6667V10.2222ZM5.11111 40.8889H15.3333V35.7778H5.11111V40.8889Z"
        fill="#20C77F"
      />
    </svg>
  );
};

export default Dashboard;
