import ChefHatIcon from "../../../Components/Icons/ChefHatIcon";

import "./Business.scss";
import CTAKitchenIcon from "../../../Components/Icons/CTAKitchenIcon";

const Business = () => {
  return (
    <section className="home-business__wrapper">
      <div className="home-business__container">
        <div className="home-business__text-con">
          <ChefHatIcon />
          <h3>Own a food business?</h3>
          <div>
            <p>
              Say hello to&nbsp;
              <span>
                Skibble Kitchens<sup>TM</sup>.
              </span>
            </p>
            <p>
              Gain insights from data on our social network, connect with your
              customers better, <br /> streamline your orders, manage your
              menus, have ultimate control over your business, <br /> and&nbsp;
              <span>grow your revenue by 10X</span> with Skibble Kitchens
              <sup>TM</sup>!
            </p>
          </div>
          <a href={"https://app.youform.io/forms/kgkmivem"}>

            <CTAKitchenIcon />
          </a>
        </div>
        <div className="home-business__image-con">
          <img src="/images/home_business_720w.webp" alt="skibble_kitchen" />
        </div>
        <div className="home-business__image-con-mobile">
          <img src="/images/home_business_375w.webp" alt="skibble_kitchen" />
        </div>
      </div>
    </section>
  );
};

export default Business;
