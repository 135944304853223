import { useState } from 'react';
import { DataGrid,  GridColDef, GridEventListener,  } from '@mui/x-data-grid';
import './FlaggedCommunities.scss';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ButtonBase, Popover } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useNavigate } from 'react-router-dom';

function GetIcon(){
  const [isOpen, setIsOpen] = useState(false)
//    navigate = useNavigate();

  function navigateToDetailPage(){
    const routeId = handleEvent;
    console.log(routeId)
    // const variable = window.location.pathname + `/${routeId}`
    // console.log(variable);
  }

  return (
    <>
      <ButtonBase onClick={() => setIsOpen(true)}>
        <MoreVertIcon />
      </ButtonBase>

      <Popover
        anchorReference='anchorPosition'
        open={isOpen} 
        onClick={() => setIsOpen(false)}
        anchorPosition={{
          top: 450,
          left: 1400
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      {/* The content of the Popover. */}
      <div className='discoveredProblems__Popover'  onClick={navigateToDetailPage}>
        <ButtonBase>
          <VisibilityIcon  className='popover__icons'/>
        </ButtonBase>
        <span>View details</span>
      </div>

      <div className='discoveredProblems__Popover'>
        <ButtonBase>
          <TaskAltIcon  className='popover__icons'/>
        </ButtonBase>
        <span>Mark as complete</span>
      </div>

      <div className='discoveredProblems__Popover'>
        <ButtonBase>
          <DeleteForeverIcon
            className='popover__icons popover__delIcon' 
          />
        </ButtonBase>
        <span>Delete mail</span>
      </div>
    </Popover>
    </>
  )
}

function getuserId(id:number){
    // console.log(id)
}

// console.log(window.location.href)

const columns: GridColDef[] = [
  { field: 'id',
   headerName: 'COMMUNITY ID', 
   width: 70,
   renderCell: (params): any => {
    return getuserId(params.formattedValue)
   }
  },
  { field: 'community', headerName: 'COMMUNITY', width: 130 },
  { field: 'creator', headerName: 'CREATOR', width: 130 },
  {
    field: 'reportedBy',
    headerName: 'REPORTED BY',
    type: 'string',
    width: 170,
  },
  {
    field: 'reason',
    headerName: 'REASON',
    // type: 'date',
    width: 130,
  },
  {
    field: 'date',
    headerName: 'REPORT DATE',
    description: 'This column has a value getter and is not sortable.',
    // sortable: false,
    sortable: true,
    width: 300,
    // valueGetter: (params: GridValueGetterParams) =>
    //   `${params.row.firstName || ''} ${params.row.lastName || ''}`,
  },
  { 
    field: 'actions',
    // headerName: 'Actions',
    description: 'Row Actions',
    width: 100, 
    renderCell: () => (
      GetIcon()
    )
  }
];

// rows are going to be dynamic...

const rows = [
//   { id: 1, lastName: 'Snow', email: "example@gmail.com", firstName: 'Jon', date: "Apr 23, 2021", message: "Alert Heap/Traffic Error",  },
  {id: 1, community: "Whole Food Plant Based", creator: "@carolenaidis", reportedBy: "@jamesSkibble", reason: "Content Theft", date: "April 18, 2022"},
  {id: 2, community: "Whole Food Plant Based", creator: "@carolenaidis", reportedBy: "@jamesSkibble", reason: "Content Theft", date: "April 19, 2022"},
  {id: 3, community: "Whole Food Plant Based", creator: "@carolenaidis", reportedBy: "@jamesSkibble", reason: "Content Theft", date: "April 20, 2022"},
  {id: 4, community: "Whole Food Plant Based", creator: "@carolenaidis", reportedBy: "@jamesSkibble", reason: "Content Theft", date: "April 21, 2022"},
  {id: 5, community: "Whole Food Plant Based", creator: "@carolenaidis", reportedBy: "@jamesSkibble", reason: "Content Theft", date: "April 22, 2022"},
  {id: 6, community: "Whole Food Plant Based", creator: "@carolenaidis", reportedBy: "@jamesSkibble", reason: "Content Theft", date: "April 23, 2022"},
  {id: 7, community: "Whole Food Plant Based", creator: "@carolenaidis", reportedBy: "@jamesSkibble", reason: "Content Theft", date: "April 24, 2022"},
  {id: 8, community: "Whole Food Plant Based", creator: "@carolenaidis", reportedBy: "@jamesSkibble", reason: "Content Theft", date: "April 25, 2022"},
  {id: 9, community: "Whole Food Plant Based", creator: "@carolenaidis", reportedBy: "@jamesSkibble", reason: "Content Theft", date: "April 26, 2022"},
  {id: 11, community: "Whole Food Plant Based", creator: "@carolenaidis", reportedBy: "@jamesSkibble", reason: "Content Theft", date: "April 18, 2022"}
];

export const handleEvent: GridEventListener<'rowClick'> = (row: any): string | number => {
    const value = row.id;
    // alert(value)
    return value;
}


const FlaggedCommunities = () => {
    const navigate = useNavigate();

    function routeToSpecificPage(row: number | string){
        navigate(window.location.pathname + `/${row}`)
    }

  return (
    <div style={{ height: 400, width: '100%' }} className="discoveredProblems">
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        checkboxSelection
        onRowClick={handleEvent}
        onRowDoubleClick={(row) => routeToSpecificPage(row.id)}
      />
    </div>
  )
}

export default FlaggedCommunities