import { FormEvent, useState } from "react";
import { ClipLoader } from "react-spinners";
import { doc, getDoc, setDoc } from "firebase/firestore";

import WaitingListConfirmation from "../components/WaitingListConfirmation/WaitingListConfirmation";
import SkibbleTextIcon from "../../Components/Icons/SkibbleTextIcon";

import { database } from "../../Firebase/firebase";

import { images } from "../../constants";
import { links } from "../../constants/links";

import "./Waitlist.scss";

const KitchenDashboardWaitlist = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (email === "") {
      alert("Please enter a valid email");
      return;
    }

    if (name === "") {
      alert("Please enter a valid name");
      return;
    }

    if (!email.includes("@")) {
      alert("Please enter a valid email");
      return;
    }
    setLoading(true);
    setError(false);

    try {
      const docRef = doc(database, "KitchenWaitlist", email);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        //doc exists
      } else {
        await setDoc(doc(database, "KitchenWaitlist", email), {
          email,
          name,
        });
      }
      setIsSubmitted(true);
      setEmail("");
      setName("");
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  if (isSubmitted)
    return (
      <WaitingListConfirmation
        setPropIsSubmitted={setIsSubmitted}
        submitted={isSubmitted}
      />
    );

  return (
    <div className="kitchenwaitinglist">
      <div className="kitchenwaitlistcontainer">
        <div className="waitlist__image-con">
          <img src={images.chefDashboardWaitlistBackground} alt="" />
        </div>
        <div className="waitlist__image-con-mobile">
          <img src={images.chefDashboardWaitlistBackgroundMobile} alt="" />
        </div>
        <div className="kitchenwaitlist__text-con">
          <div>
            <div className="waitlist__texticon-con">
              <SkibbleTextIcon />
            </div>
            <h2>Introducing Skibble Kitchens</h2>

            <div className="waitlist__textcontent">
              <p>
                Streamline Your Food Business Operations with Ease! Are you a
                food business owner looking to simplify and streamline your
                operations? Look no further! Skibble Kitchens is here
                to revolutionize the way you manage your food business.
              </p>
            </div>

            <form
              className="kitchen-waitlist__form-con"
              onSubmit={handleSubmit}
            >
              <input
                value={name}
                onChange={(e) => setName(e.target.value)}
                type="text"
                placeholder="Full Name"
                required
              />
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                placeholder="Email Address"
                required
              />
              <button disabled={loading}>
                {loading ? (
                  <ClipLoader color="white" size={10} />
                ) : (
                  "JOIN THE WAITLIST"
                )}
              </button>
            </form>
            {error && (
              <h3 className="error-text">
                An error occurred. Please try again
              </h3>
            )}
            <div className="kitchenwaitlist__footer">
              <small>&copy; Skibble {new Date().getFullYear()}</small>
              <div className="waitlist__socials">
                {links.map((link) => (
                  <a href={link.socialLink} key={link.id}>
                    <img src={link.img} alt={`link-${link.id}`} />
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KitchenDashboardWaitlist;
