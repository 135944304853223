import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import Copyright from '../../Components/Copyright/Copyright';
import { images } from '../../constants';
import './AdminPortal.scss';
import { useNavigate } from 'react-router-dom';

const AdminPortal = () => {
    const [formData, setFormData] = useState({
        email: "",
        password: ""
    })
    const navigate = useNavigate();

    const handleChange = (e: any) => {
        const value = e.target.value;
        const name = e.target.name;

        setFormData({
            ...formData,
            [name]: value
        })
    } 

    const goToDashboard = (e: any) => {
        e.preventDefault();
        if(formData.email === "" || formData.password === "" || !formData.email.includes("@")){
            alert("Please fill the form Correctly")
            return;
        }
        console.log(formData)

        navigate('/admin-dashboard/emails')
    }
    
  return (
    <section id="adminPortal">
        <form className="adminPortal__container">
            <div className="adminPortal__header">
                <img src={images.Skibble} alt="skibble" className='adminPortal__headerImg'/>
                <h3>Admin Portal</h3>
            </div>

            <div className="adminPortal__fields">
                <input 
                    type="email"  
                    placeholder='Email *'
                    required
                    value={formData.email}
                    name="email"
                    onChange={handleChange}
                />

                <input 
                    type="password"  
                    placeholder='Password *'
                    required
                    value={formData.password}
                    name="password"
                    onChange={handleChange}
                />
            </div>

            <button className="adminPortal__button" type='submit' onClick={goToDashboard}>
                Sign In
            </button>

            <p className='adminPortal__forgotPassword'><Link to={'#forgotPassword'} style={{ color: "#00BF6D"}}>Forgot Password?</Link></p>
        </form>

        <div className="adminPortal__footer">
            <Copyright copyRightStyles={{ fontWeight: "bold" }}/>
        </div>
    </section>
  )
}

export default AdminPortal