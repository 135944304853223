import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import {
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
} from "firebase/app-check";

//firebase web config
const firebaseConfig = {
  apiKey: "AIzaSyCxlQtMJC1wqSBVSrJjF-KSjTJvZPSX_VQ",
  authDomain: "skibble-d275e.firebaseapp.com",
  projectId: "skibble-d275e",
  storageBucket: "skibble-d275e.appspot.com",
  messagingSenderId: "477071581496",
  appId: "1:477071581496:web:9295b4ca2bdecca1d9e1cd",
  measurementId: "G-YSZQXM3WY9",
};

const appInstance = initializeApp(firebaseConfig);

// Create a ReCaptchaEnterpriseProvider instance using your reCAPTCHA Enterprise
// site key and pass it to initializeAppCheck().
initializeAppCheck(appInstance, {
  provider: new ReCaptchaEnterpriseProvider("6Lcd7pQnAAAAAA3XYYQ5tDuFowKp7WJxEjo-n9zM"),
  isTokenAutoRefreshEnabled: true // Set to true to allow auto-refresh.
});

export default appInstance;
export const database = getFirestore(appInstance);

//When the user clicks that button,
//define a function that calls the cloud function
//The function name is storeEmailToWaitlist
//Just like this:
// functions.httpsCallable("storeEmailToWaitlist")
//Calling that function returns two possible string results:
// 1) "doc-exists": Means that the user had already been added to the waiting list - Display a message showing that info
//2) "success": The transaction was successful - Display the successful message

//  if doc exists => response will be they have been added to the waiting list
//  if response is successful => we'll tell them that their email has been added.
