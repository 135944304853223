import { FormEvent, useState } from "react";
import { Link } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { doc, getDoc, setDoc } from "firebase/firestore";

import Arrow from "../Icons/Arrow";
import { images } from "../../constants";

import "./Footer.scss";
import { database } from "../../Firebase/firebase";

type FooterProp = {
  styles?: React.CSSProperties;
};

const Footer = ({ styles }: FooterProp) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (email === "") {
      alert("Please enter a valid email");
      return;
    }

    if (!email.includes("@")) {
      alert("Please enter a valid email");
      return;
    }

    setLoading(true);
    setError(false);

    try {
      const docRef = doc(database, "NewsLetter", email);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        //doc exists
      } else {
        await setDoc(doc(database, "NewsLetter", email), {
          email,
        });
      }
      setIsSubmitted(true);
      setEmail("");
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <section id="footer" className="container" style={styles}>
      <div className="footer__one">
        <img src={images.Skibble} alt="skibble" />

        <div className="footer__skibbleDescription">
          <p>Skibble is a social network for</p>
          <p>food lovers and food businesses</p>
          <p>across the globe.</p>

          <p className="footer__motto">Eat. Connect. Share.</p>
        </div>

        <div className="footer__socialLinks">
          <img
            src={images.FacebookLink}
            alt="facebook"
            onClick={() =>
              window.open("https://web.facebook.com/theskibbleapp/", "_blank")
            }
          />
          <img
            src={images.InstagramLink}
            alt="instagram"
            onClick={() =>
              window.open("https://www.instagram.com/skibble.app", "_blank")
            }
          />
          <img
            src={images.FooterLinkedIn}
            alt="linkedin"
            onClick={() =>
              window.open(
                "https://www.linkedin.com/company/skibbleapp/",
                "_blank"
              )
            }
          />
        </div>
      </div>

      <div
        className="footer__twoThree"
        style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}
      >
        <div className="footer__two">
          <h2>About Us</h2>
          <div>
            <p>
              <Link to={"/about"} className="footerLink_p">
                About Us
              </Link>
            </p>
            <p>
              <a
                href="mailto: hello@skibble.app"
                target="_blank"
                rel="noreferrer"
                className="footerLink_p"
              >
                Contact Us
              </a>
            </p>
          </div>
        </div>

        <div className="footer__three">
          <h2>Company</h2>
          <div>
            <p>
              <Link to={"/kitchen"} className="footerLink_p">
                Kitchen Dashboard™
              </Link>
            </p>
            <p>
              <Link to={"/privacy"} className="footerLink_p">
                Privacy Policy
              </Link>
            </p>
            <p>
              <Link to={"/terms"} className="footerLink_p">
                Terms of Use
              </Link>
            </p>
          </div>
        </div>
      </div>

      <div className="footer__four">
        <h2>Get in touch</h2>

        <div className="footer__fourText">
          <p>Don't miss any of our mouthwatering</p>
          <p>content about food, foodies and chefs.</p>
          <p>Sign up with your email</p>
        </div>
        {isSubmitted ? (
          <p>Thanks for subscribing</p>
        ) : (
          <>
            <form className="footer__form-con" onSubmit={handleSubmit}>
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                placeholder="Email"
                required
              />
              <button disabled={loading}>
                {loading ? (
                  <ClipLoader color="white" size={10} />
                ) : (
                  <>
                    Subscribe <Arrow />
                  </>
                )}
              </button>
            </form>
            {error && (
              <h3 className="error-text">
                An error occurred. Please try again
              </h3>
            )}
          </>
        )}

        <div className="footer__socialLinksMoblie">
          <img
            src={images.FacebookLink}
            alt="facebook"
            onClick={() =>
              window.open("https://web.facebook.com/theskibbleapp/", "_blank")
            }
          />
          <img
            src={images.InstagramLink}
            alt="instagram"
            onClick={() =>
              window.open("https://www.instagram.com/skibble.app", "_blank")
            }
          />
          <img
            src={images.FooterLinkedIn}
            alt="linkedin"
            onClick={() =>
              window.open(
                "https://www.linkedin.com/company/skibbleapp/",
                "_blank"
              )
            }
          />
        </div>
      </div>
    </section>
  );
};

export default Footer;
