import { useEffect } from "react"

const TopOfPage = () => {

    useEffect(() => {   
        window.scrollTo(0, 0);
    },[])

  return (
    <div>TopOfPage</div>
  )
}

export default TopOfPage