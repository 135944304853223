import { ReactNode } from "react";

import Dashboard from "./Dashboard";
import Customer from "./Customer";
import Seamless from "./Seamless";
import CTAGreenIcon from "../../../Components/Icons/CTAGreenIcon";

import "./Centralized.scss";

interface ICardProps {
  heading: string;
  text: string;
  svgComp: ReactNode;
}

const Card = ({ heading, text, svgComp }: ICardProps) => {
  return (
    <div className="centralized-card__container">
      <div className="centralized-card__image-con">{svgComp}</div>
      <div className="centralized-card__text-con">
        <h4>{heading}</h4>
        <p>{text}</p>
      </div>
    </div>
  );
};

const Centralized = () => {
  return (
    <section className="kitchen-centralized__wrapper">
      <div className="kitchen-centralized__container">
        <div className="kitchen-centralized__image-con">
          <img src="/images/kitchen_centralized.webp" alt="kitchen_dashboard" />
        </div>
        <div className="kitchen-centralized__text-con">
          <Card
            heading="Centralized Dashboard"
            text="Enjoy a centralized hub where you can effortlessly oversee your food business operations. Get a holistic view of sales, menu availability, order summaries, and performance metrics, allowing you to make data-driven decisions for your business's success."
            svgComp={<Dashboard />}
          />
          <Card
            heading="Strong Customer Relationships"
            text="Connect with your customers through our food social network, launch campaigns that target foodies in your city, attract new customers, and reward your most loyal customers."
            svgComp={<Customer />}
          />
          <Card
            heading="Seamless Menu Management"
            text="Create, customize, and categorize your menus within the Skibble Kitchens. Easily add, edit, or remove items, set prices, and apply discounts. Keep your menus up-to-date across all platforms, ensuring consistency and accuracy."
            svgComp={<Seamless />}
          />
          <a href={"https://app.youform.io/forms/kgkmivem"}>
            <CTAGreenIcon />
          </a>
        </div>
      </div>
    </section>
  );
};

export default Centralized;
