import "./AboutHero.scss";

const AboutHero = () => {
  return (
    <section className="about-hero__wrapper">
      <div className="about-hero__image-con">
        <img src="/images/about_hero_skibble_icon.webp" alt="skibble2.0" />
      </div>
      <div className="about-hero__text-con">
        <h3>
          Welcome to Skibble, <br /> the food social network!
        </h3>
      </div>
    </section>
  );
};

export default AboutHero;
