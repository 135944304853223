import "./FaqHero.scss";

const FaqHero = () => {
  return (
    <section className="faq-hero__wrapper">
      <div className="faq-hero__text-con">
        <h1>FAQs</h1>
        <p>
          We’ve put together most common challenges that you might face as a
          user and the solution to these common challenges.
        </p>
      </div>
    </section>
  );
};

export default FaqHero;
