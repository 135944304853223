import "./Recipe.scss";

const Recipe = () => {
  return (
    <section className="home-recipe__wrapper">
      <div className="home-recipe__container">
        <div className="home-recipe__text-con">
          <h3>
            Find and create <br /> delicious recipes with Skibble
          </h3>
          <p>
            With access to thousands of pre-curated recipes, you can <br />
            easily browse and discover dishes that suit your taste buds. <br />
            Create your unique recipes and share them with loved ones.
          </p>
          <div>
            <a
              href="https://apps.apple.com/app/skibble-food-social-network/id1633887491"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/images/app_store.png" alt="" />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.mcfranchostudios.skibble"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/images/playstore.png" alt="" />
            </a>
          </div>
        </div>
        <div className="home-recipe__image-con">
          <img src="/images/home_recipe.webp" alt="" />
        </div>
      </div>
    </section>
  );
};

export default Recipe;
