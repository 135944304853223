import { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import ReactGA from "react-ga";

import Blog from "./Components/Blog/Blog";
import Navbar from "./Components/Navbar/Navbar";
import AboutPage from "./Pages/AboutPage/AboutPage";
import BlogPage from "./Pages/BlogPage/BlogPage";
import ContactPage from "./Pages/ContactPage/Contact";
import PolicyPage from "./Pages/PolicyPage/PolicyPage";
import TermsPage from "./Pages/TermsPage/TermsPage";
import NotFound from "./Pages/NotFound/NotFound";
import AdminPortal from "./Pages/AdminPortal/AdminPortal";
import AdminDashboard from "./Pages/AdminDashboard/AdminDashboard";
import EmailTable from "./Pages/AdminDashboard/Components/EmailTable/EmailTable";
import ReportTable from "./Pages/AdminDashboard/Components/ReportTable/ReportTable";
import DevTable from "./Pages/AdminDashboard/Components/DevTable/DevTable";
import KitchenDashboardWaitlist from "./Pages/KitchenDashboardWaitlist/Waitlist";
import SkibbleHomePage from "./Pages/SkibbleHomePage";
import FaqPage from "./Pages/Faq/Faq";
import KitchenPage from "./Pages/Kitchen/Kitchen";

import { getSiteAnalytics } from "./utils/Analytics";

import "./App.css";

const TRACKING_ID = "UA-173059292-1";
// const TRACKING_ID = "G-CX8CLZ6ZZ8"
ReactGA.initialize(TRACKING_ID);

function App() {
  useEffect(() => {
    // ReactGA.pageview(window.location.pathname)
    getSiteAnalytics();
  }, []);

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route
            path="/"
            element={
              <SkibbleHomePage>
                <Navbar />
              </SkibbleHomePage>
            }
          />

          <Route
            path="/about"
            element={
              <AboutPage>
                <Navbar greenBackground whiteLinkText={{ color: "white" }} />
              </AboutPage>
            }
          />

          <Route
            path="/kitchen"
            element={
              <KitchenPage>
                <Navbar greenBackground whiteLinkText={{ color: "white" }} />
              </KitchenPage>
            }
          />

          <Route
            path="/faq"
            element={
              <FaqPage>
                <Navbar greenBackground whiteLinkText={{ color: "white" }} />
              </FaqPage>
            }
          />

          <Route
            path="/blog"
            element={
              <BlogPage>
                <Navbar greenBackground whiteLinkText={{ color: "white" }} />
              </BlogPage>
            }
          />

          <Route
            path="blog/:blogId"
            element={
              <Blog>
                <Navbar greenBackground whiteLinkText={{ color: "white" }} />
              </Blog>
            }
          ></Route>

          <Route path="/contact" element={<ContactPage />} />
          <Route path="/privacy" element={<PolicyPage />} />

          <Route path="/terms" element={<TermsPage />} />

          <Route path="/skibble2-waitlist" element={<Navigate to="/" replace />} />

          <Route
            path="/kitchen-dashboard-waitlist"
            element={<KitchenDashboardWaitlist />}
          />

          <Route path="/admin" element={<AdminPortal />} />

          <Route path="/admin-dashboard" element={<AdminDashboard />}>
            <Route path="emails" element={<EmailTable />} />
            <Route path="reports" element={<ReportTable />} />
            {/* <Route path='reports' element={<ReportTable />}>
                  <Route path='reports/:id' element={<SinglePage />} />
                </Route> */}
            <Route path="dev" element={<DevTable />} />
          </Route>

          <Route
            path="*"
            element={
              <NotFound>
                <Navbar />
              </NotFound>
            }
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
