import React from 'react'
import { images } from '../../constants';
import { goToAndroidLink, goToAppleLink } from '../../utils/link';
import './Buttons.scss';

type ButtonProp = {
    styles?: React.CSSProperties;
    buttonStyles?: React.CSSProperties;
    mottoButtonStyles?: boolean;
    flexColumn?: boolean;

    // bug fixes
    marginBottomRemoveOnMobile?: boolean;
    showButtonAtTheTop?: boolean;
}

const Buttons = ({ styles, buttonStyles, marginBottomRemoveOnMobile, showButtonAtTheTop}: ButtonProp) => {
  return (
    <section id="buttons" style={styles} className={`
      ${showButtonAtTheTop && 'showButtonAtTheTop'}
    `}>
        <img src={images.PlayStore} alt="PlayStore" style={buttonStyles} onClick={() => goToAndroidLink()} 
          className={`
          ${marginBottomRemoveOnMobile && 'marginBottomRemoveOnMobile'}
          `}
        />
        <img src={images.AppStore} alt="AppStore" style={buttonStyles} onClick={() => goToAppleLink()}/>
    </section>
  )
}

export default Buttons