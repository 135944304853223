const Arrow = () => {
  return (
    <svg
      width="14"
      height="11"
      viewBox="0 0 14 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.31745 1.84088C8.02455 1.54798 8.02455 1.07311 8.31745 0.780217C8.61034 0.487324 9.08522 0.487324 9.37811 0.780217L13.3781 4.78022C13.671 5.07311 13.671 5.54798 13.3781 5.84088L9.37811 9.84088C9.08522 10.1338 8.61034 10.1338 8.31745 9.84088C8.02455 9.54798 8.02455 9.07311 8.31745 8.78022L11.0371 6.06055H1.34778C0.933565 6.06055 0.597778 5.72476 0.597778 5.31055C0.597778 4.89633 0.933565 4.56055 1.34778 4.56055H11.0371L8.31745 1.84088Z"
        fill="white"
      />
    </svg>
  );
};

export default Arrow;
