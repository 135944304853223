import { useState } from 'react'
import './AdminDashboard.scss';
import AdminNav from './Components/AdminNav/AdminNav';
import { images } from '../../constants';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';

const AdminDashboard = () => {
    const [sidebarItems] = useState([
        {
            id: 1, 
            name: "Emails",
            image: images.EmailIcon, 
            route: '/admin-dashboard/emails'
        },
        {
            id: 2,
            name: "Reports",
            image: images.ExclamationIcon,
            route: '/admin-dashboard/reports'
        },
        {
            id: 3, 
            name: "For Devs", 
            image: images.DevIcon,
            route: '/admin-dashboard/dev'
        }
    ])
    const navigate = useNavigate();
    const location = useLocation();
    console.log(location.pathname)
  return (
    <div className='adminDashboard'>
        <AdminNav />

        {/* main dashboard */}
        <div className="adminDashboard__container">
            <div className="adminDashboard__sidebar">
                {sidebarItems.map(items => (
                    <button className='adminDashboard__sidebarButton' onClick={() => navigate(`${items.route}`)}>
                        <img src={items.image} alt="icon" />
                        <p>{items.name}</p>
                        {items.name === "Reports" ? <p className='adminDashboard__noOfReports'>2</p> : ''}
                    </button>
                ))}
            </div>

            <div className="adminDashboard__main">
                <Outlet />
            </div>
            
            <div>
                
            </div>
        </div>
    </div>
  )
}

export default AdminDashboard