const BlackArrow = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.7788 9.03033C13.4859 8.73744 13.4859 8.26256 13.7788 7.96967C14.0716 7.67678 14.5465 7.67678 14.8394 7.96967L18.8394 11.9697C19.1323 12.2626 19.1323 12.7374 18.8394 13.0303L14.8394 17.0303C14.5465 17.3232 14.0716 17.3232 13.7788 17.0303C13.4859 16.7374 13.4859 16.2626 13.7788 15.9697L16.4984 13.25H6.80908C6.39487 13.25 6.05908 12.9142 6.05908 12.5C6.05908 12.0858 6.39487 11.75 6.80908 11.75H16.4984L13.7788 9.03033Z"
        fill="#233748"
      />
    </svg>
  );
};

export default BlackArrow;
