import "./Capture.scss";

const Capture = () => {
  return (
    <section className="home-capture__wrapper">
      <div className="home-capture__container">
        <div className="home-capture__text-con">
          <h3>
            Capture and share your <br /> unforgettable food <br /> moments with
            Skibble
          </h3>
          <p>
            Are you tired of losing track of those yummy meals? Skibble is here
            to help you capture those delicious food moments to share with your
            family and friends.
          </p>
          <div>
            <a
              href="https://apps.apple.com/app/skibble-food-social-network/id1633887491"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/images/app_store.png" alt="" />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.mcfranchostudios.skibble"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/images/playstore.png" alt="" />
            </a>
          </div>
        </div>
        <div className="home-capture__image-con">
          <img src="/images/home_capture_picture.webp" alt="skibble2.0" />
        </div>
      </div>
    </section>
  );
};

export default Capture;
