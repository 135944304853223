import "./Motto.scss";

const Motto = () => {
  return (
    <section className="about-motto__wrapper">
      <div className="about-motto__container">
        <div className="about-motto__text-con">
          <h3>Start your food journey with us</h3>
          <p>Join a vibrant community of food lovers and chefs worldwide</p>
          <div>
            <a
              href="https://apps.apple.com/app/skibble-food-social-network/id1633887491"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/images/app_store.png" alt="" />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.mcfranchostudios.skibble"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/images/playstore.png" alt="" />
            </a>
          </div>
        </div>
      </div>
      <div className="about-motto__container-mobile">
        <div className="about-motto__text-con-mobile">
          <h3>Start your food journey with us</h3>
          <p>Join a vibrant community of food lovers and chefs worldwide</p>
          <div>
            <a
              href="https://apps.apple.com/app/skibble-food-social-network/id1633887491"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/images/app_store.png" alt="" />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.mcfranchostudios.skibble"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/images/playstore.png" alt="" />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Motto;
