import FaqHero from "../../sections/Faq/FaqHero/FaqHero";
import FaqDetails from "../../sections/Faq/FaqDetails/FaqDetails";
import Motto from "../../sections/About/Motto/Motto";
import Footer from "../../Components/Footer/Footer";
import Copyright from "../../Components/Copyright/Copyright";

import { PageProps } from "../SkibbleLandingPage";
import TopOfPage from "../../utils/TopOfPage";

import "./Faq.scss";

const FaqPage = ({ children }: PageProps) => {
  TopOfPage();

  return (
    <section id="faqPage">
      <div className="skibble-faq-nav-con">{children}</div>

      <FaqHero />

      <FaqDetails />

      <Motto />

      <Footer />
      <Copyright />
    </section>
  );
};

export default FaqPage;
