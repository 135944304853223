import './BlogPostHero.scss';

const BlogPostHero = () => {
  return (
    <section id="blogPostHero">
        
    </section>
  )
}

export default BlogPostHero