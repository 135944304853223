import { SyntheticEvent, useState } from "react";
import { Link } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import "./Faq.scss";

const questions = [
  {
    id: 1,
    question: "What is Skibble?",
    answers:
      "Skibble is a food social network that connects food lovers, chefs, and food businesses, allowing users to share food moments, discover recipes, join food communities, and share food meets.",
  },
  {
    id: 2,
    question: "How can I share my food experiences on Skibble?",
    answers:
      "Skibble allows you to create and share your memorable food experiences, whether at a restaurant, party or enjoying homemade meals. You can add location tags and share recipes, create a Skibble meet making it easy for your friends and family to build connections over food.",
  },
  {
    id: 3,
    question: "Can I host my own food events and meets on Skibble?",
    answers:
      "Yes, Skibble provides access to thousands of pre-curated recipes. You can explore a wide range of dishes and also create and share your own unique recipes with others.",
  },
  {
    id: 4,
    question: "How can I discover new food experiences on Skibble?",
    answers:
      "Skibble provides a platform for users to share their food moments, recipes, and recommendations. By exploring these posts and engaging with the community, you can discover exciting new food experiences.",
  },
];

const Faq = () => {
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <section id="faq" className="container">
      <h1 className="faq__heading">FAQs</h1>

      <div className="faq__container">
        {questions.map(({ answers, id, question }) => (
          <Accordion
            key={id}
            expanded={expanded === `panel${id}`}
            onChange={handleChange(`panel${id}`)}
          >
            <AccordionSummary
              expandIcon={
                expanded === `panel${id}` ? <RemoveIcon /> : <AddIcon />
              }
              aria-controls={`panel${id}a-content`}
              id={`panel${id}a-header`}
            >
              <h3>{question}</h3>
            </AccordionSummary>

            <AccordionDetails>
              <p>{answers}</p>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>

      <p className="faq__see-more">
        <Link to={"/faq"}>See more </Link>
      </p>
    </section>
  );
};

export default Faq;
