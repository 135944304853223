import React from 'react'
import './Copyright.scss';

type CopyRightProps = {
  copyRightStyles?: React.CSSProperties;
}

const Copyright = ({ copyRightStyles }: CopyRightProps) => {
    let date = new Date();
  return (
    <section id="copyright" style={copyRightStyles}>Copyright &copy; {date.getFullYear()} Skibble</section>
  )
}

export default Copyright