const CommunityIcon = () => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M48.0001 19.0933C47.8401 19.0666 47.6534 19.0666 47.4934 19.0933C43.8134 18.9599 40.8801 15.9466 40.8801 12.2133C40.8801 8.39992 43.9467 5.33325 47.7601 5.33325C51.5734 5.33325 54.6401 8.42659 54.6401 12.2133C54.6134 15.9466 51.6801 18.9599 48.0001 19.0933Z"
        stroke="#233748"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.2534 38.5063C48.9067 39.1196 52.9334 38.4796 55.7601 36.5863C59.5201 34.0796 59.5201 29.973 55.7601 27.4663C52.9067 25.573 48.8267 24.9329 45.1734 25.5729"
        stroke="#233748"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.92 19.0933C16.08 19.0666 16.2666 19.0666 16.4266 19.0933C20.1066 18.9599 23.04 15.9466 23.04 12.2133C23.04 8.39992 19.9733 5.33325 16.16 5.33325C12.3466 5.33325 9.27997 8.42659 9.27997 12.2133C9.30663 15.9466 12.24 18.9599 15.92 19.0933Z"
        stroke="#233748"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.6667 38.5063C15.0134 39.1196 10.9867 38.4796 8.16003 36.5863C4.40003 34.0796 4.40003 29.973 8.16003 27.4663C11.0134 25.573 15.0934 24.9329 18.7467 25.5729"
        stroke="#233748"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.9999 39.0132C31.8399 38.9865 31.6533 38.9865 31.4933 39.0132C27.8133 38.8799 24.8799 35.8665 24.8799 32.1332C24.8799 28.3198 27.9466 25.2532 31.7599 25.2532C35.5733 25.2532 38.6399 28.3465 38.6399 32.1332C38.6133 35.8665 35.6799 38.9065 31.9999 39.0132Z"
        stroke="#233748"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.24 47.413C20.48 49.9196 20.48 54.0263 24.24 56.533C28.5066 59.3863 35.4933 59.3863 39.76 56.533C43.52 54.0263 43.52 49.9196 39.76 47.413C35.52 44.5863 28.5066 44.5863 24.24 47.413Z"
        stroke="#233748"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CommunityIcon;
