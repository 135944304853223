import { SyntheticEvent, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import "./FaqDetails.scss";

const questions = [
  {
    id: 1,
    question: "What is Skibble?",
    answers:
      "Skibble is a food social network that connects food lovers, chefs, and food businesses, allowing users to share food moments, discover recipes, join food communities, and share food meets.",
  },
  {
    id: 2,
    question: "How can I share my food experiences on Skibble?",
    answers:
      "Skibble allows you to create and share your memorable food experiences, whether at a restaurant, party or enjoying homemade meals. You can add location tags and share recipes, create a Skibble meet making it easy for your friends and family to build connections over food.",
  },
  {
    id: 3,
    question: "Can I host my own food events and meets on Skibble?",
    answers:
      "Yes, Skibble provides access to thousands of pre-curated recipes. You can explore a wide range of dishes and also create and share your own unique recipes with others.",
  },
  {
    id: 4,
    question: "How can I discover new food experiences on Skibble?",
    answers:
      "Skibble provides a platform for users to share their food moments, recipes, and recommendations. By exploring these posts and engaging with the community, you can discover exciting new food experiences.",
  },
  {
    id: 5,
    question: "Can I rate and review food experiences on Skibble?",
    answers:
      "Skibble allows users to rate and review their recipes using a star rating system. This helps others make informed decisions and find the best culinary delights.",
  },
  {
    id: 6,
    question: "Is Skibble available globally?",
    answers:
      "Skibble is available in all locations across the globe. However, the Skibble Meet feature is only available for Canadians at the moment. It will get to your location soon!",
  },
  {
    id: 7,
    question: "What are the benefits of joining a food community on Skibble?",
    answers:
      "By joining a food community on Skibble, you can expand your culinary knowledge, exchange tips, and tricks, discover new meal ideas, and connect with passionate food lovers who share your interests.",
  },
  {
    id: 8,
    question: "Can I earn rewards on Skibble?",
    answers:
      "No, but you can get rewards or offers for sharing food moments and active participation in your community. This is totally dependent on fellow users(Skibblers) or the community admin, not Skibble.",
  },
  {
    id: 9,
    question: "Are there privacy options on Skibble?",
    answers:
      "Yes, Skibble offers privacy settings for your Skibble meets and recipes. You can choose to make them public or visible only to selected friends and communities.",
  },
  {
    id: 10,
    question: "What is Skibble Meets?",
    answers:
      "Skibble Meets is our solution to help you physically connect with food lovers nearby at your favorite local restaurant or cafe.",
  },
  {
    id: 11,
    question: "How can I share my food experiences with Skibble?",
    answers:
      "Share your memorable food moments through posts and location tagging with Skibble's easy-sharing features.",
  },
  {
    id: 12,
    question: "Is Skibble Meets a paid feature?",
    answers: "At the moment, it is free.",
  },
];

const FaqDetails = () => {
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <section className="faq-details__wrapper">
      <div className="faq__container">
        {questions.map(({ answers, id, question }) => (
          <Accordion
            key={id}
            expanded={expanded === `panel${id}`}
            onChange={handleChange(`panel${id}`)}
          >
            <AccordionSummary
              expandIcon={
                expanded === `panel${id}` ? <RemoveIcon /> : <AddIcon />
              }
              aria-controls={`panel${id}a-content`}
              id={`panel${id}a-header`}
            >
              <h3>{question}</h3>
            </AccordionSummary>

            <AccordionDetails>
              <p>{answers}</p>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </section>
  );
};

export default FaqDetails;
