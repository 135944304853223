import { Tabs, Tab, Typography } from '@mui/material'
import { Box } from '@mui/system';
import React from 'react'
import './ReportTable.scss'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import UserReport from '../../Tables/Report/UserReport/UserReport';
import FlaggedCommunities from '../../Tables/Report/FlaggedCommunities/FlaggedCommunities';
import ReportedUsers from '../../Tables/Report/ReportedUsers/ReportedUsers';

type TabPanelProps =  {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const ReportTable = () => {

  const [value, setValue] = React.useState(0);

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className='reportTable'>
       <h2 className='reportTable__header'>Reports</h2>

      <div className="reportTable__tabs">
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Flagged Communities" {...a11yProps(0)} sx={{borderBottomColor: "red"}}/>
          <Tab label="Reported posts" {...a11yProps(1)} />
          <Tab label="Reported users" {...a11yProps(2)} />
        </Tabs>
      </div>

      
      <div className="report__search">
        <input type="text" placeholder='Search for email, Customer...'/>

        <button>
          <FilterAltOutlinedIcon className='emailTable__buttonIcons'/>
          Filter
        </button>

        <button>
          <OpenInNewOutlinedIcon className='emailTable__buttonIcons'/>
          Export CSV
        </button>
      </div>

      
      <TabPanel value={value} index={0}>
        <FlaggedCommunities />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <UserReport />
        <FlaggedCommunities />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ReportedUsers />
      </TabPanel>
    </div>
  )
}

export default ReportTable