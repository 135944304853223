import { ButtonBase, Popover } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React, { useState } from 'react'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import './UserReport.scss';
import VisibilityIcon from '@mui/icons-material/Visibility';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

function GetIcon(){
    const [isOpen, setIsOpen] = useState<boolean>(false)
    return (
      <React.Fragment>
        <ButtonBase onClick={() => setIsOpen(true)}>
          <MoreVertIcon />
        </ButtonBase>
  
        <Popover
          anchorReference='anchorPosition'
          open={isOpen} 
          onClick={() => setIsOpen(false)}
          anchorPosition={{
            top: 450,
            left: 1400
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {/* The content of the Popover. */}
        <div className='discoveredProblems__Popover'>
          <ButtonBase>
            <VisibilityIcon  className='popover__icons'/>
          </ButtonBase>
          <span>View details</span>
        </div>
  
        <div className='discoveredProblems__Popover'>
          <ButtonBase>
            <TaskAltIcon  className='popover__icons'/>
          </ButtonBase>
          <span>Mark as complete</span>
        </div>
  
        <div className='discoveredProblems__Popover'>
          <ButtonBase>
            <DeleteForeverIcon
              className='popover__icons popover__delIcon' 
            />
          </ButtonBase>
          <span>Delete mail</span>
        </div>
      </Popover>
      </React.Fragment>
    )
  }

function ReasonButton(cause: string){
    return(
        <div className={`userReport__reason ${cause}`}>
            {cause}
        </div>
    )
}

const columns: GridColDef[] = [
    { field: 'id', headerName: 'POST ID', width: 70 },
    { field: 'postedBy', headerName: 'POSTED BY', width: 200 },
    { field: 'reportedBy', headerName: 'REPORTED BY', width: 200 },
    {
      field: 'reason',
      headerName: 'REASON',
      width: 170,
      renderCell: (params) => {
        return ReasonButton(params.value)
      }
    },
    {
      field: 'date',
      headerName: 'REPORT DATE',
      type: 'date',
      width: 130,
    },
    { 
      field: 'actions',
      // headerName: 'Actions',
      description: 'Row Actions',
      width: 100, 
      renderCell: () => (
        GetIcon()
      )
    }
  ];


const rows = [
    { id: 1, postedBy: '@Snow',  reportedBy: '@Jon', reason: 'Violence' ,date: "Apr 23, 2021",  },
    { id: 2, postedBy: '@Lannister',  reportedBy: '@Cersei', reason: 'ChildAbuse' ,date: "Apr 23, 2021", },
    { id: 3, postedBy: '@Yoma', reportedBy: '@Jaime', reason: 'Spam' ,date: "Apr 23, 2021", },
    { id: 4, postedBy: '@Stark',  reportedBy: '@Arya', reason: 'Pornography', date: "Apr 25, 2021", },
    { id: 5, postedBy: '@Targaryen',  reportedBy: '@Daenerys', reason: 'Copyright' ,date: "Apr 23, 2021", },
    { id: 6, postedBy: '@Melisandre',  reportedBy: "@Yoma", reason: 'Others' ,date: "Apr 23, 2021", },
    { id: 7, postedBy: '@Clifford',  reportedBy: '@Ferrara', reason: 'Violence' ,date: "Apr 30, 2021", },
    { id: 8, postedBy: '@Frances',  reportedBy: '@Rossini', reason: 'ChildAbuse' ,date: "Apr 23, 2021", },
    { id: 9, postedBy: '@Roxie',  reportedBy: '@Harvey', reason: 'Spam' ,date: "Apr 23, 2021", },
  ];
  

const UserReport = () => {
  return (
    <div style={{ height: 400, width: '100%' }} className="discoveredProblems">
        <DataGrid
        rows={rows}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        checkboxSelection
        />
    </div>
  )
}

export default UserReport