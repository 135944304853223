import React, { useState } from "react";
import "./Navbar.scss";
import { Link } from "react-router-dom";
import NavDrawer from "../../Organisms/NavDrawer/NavDrawer";
import { useLocation, useNavigate } from "react-router-dom";
import NavLogo from "../Icons/NavLogo";

type NavbarProps = {
  greenBackground?: boolean;
  whiteLinkText?: React.CSSProperties;
};

const Navbar = ({ greenBackground, whiteLinkText }: NavbarProps) => {
  const [links] = useState([
    {
      id: 1,
      linkRoute: "/",
      text: "Home",
    },
    {
      id: 2,
      linkRoute: "/about",
      text: "About Us",
    },
    {
      id: 3,
      linkRoute: "/kitchen",
      text: "For Food Businesses",
    },
    {
      id: 4,
      linkRoute: "/contact",
      text: "Contact Us",
    },
  ]);

  const navigate = useNavigate();

  const goToHomePage = () => {
    navigate("/");
  };

  let smallCircleVar: string;
  const { pathname } = useLocation();
  let smallCircleColor; // for small circle under the nav links
  // pathname === '/blog' || '/policy' || `/blog/${id}` ? true : false;
  if (
    pathname === "/blog" ||
    pathname === "/privacy" ||
    pathname === `/blog/1` ||
    pathname === "/terms" ||
    pathname === "/about" ||
    pathname === "/faq" ||
    pathname === "/kitchen"
  ) {
    smallCircleColor = true;
  } else {
    smallCircleColor = false;
  }

  if (smallCircleColor) {
    smallCircleVar = "smallCircleVar";
  } else {
    smallCircleVar = "";
  }

  // console.log(smallCircleVar);

  return (
    <nav id="navbar" className={`${greenBackground ? "changeNavMode" : ""}`}>
      {greenBackground ? (
        <NavLogo fill="#ffffff" onClick={goToHomePage} />
      ) : (
        <NavLogo onClick={goToHomePage} />
      )}

      <div className="navbar__lists">
        <ul>
          {links.map((link) => (
            <li key={link.id} className="navList">
              <Link
                to={link.linkRoute}
                style={whiteLinkText && { color: "white" }}
              >
                {link.text}
              </Link>
              <p className={`smallCircle ${smallCircleVar}`}></p>
            </li>
          ))}
        </ul>
      </div>

      <div className="navbar__drawer">
        <NavDrawer />
      </div>
    </nav>
  );
};

export default Navbar;
