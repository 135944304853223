import { ReactNode } from "react";

import Multi from "./Multi";
import Delivery from "./Delivery";
import Analytics from "./Analytics";

import "./Cards.scss";

interface ICardProps {
  heading: string;
  text: string;
  svgComp: ReactNode;
}

const Card = ({ heading, text, svgComp }: ICardProps) => {
  return (
    <div className="cards-card__container">
      <div className="cards-card__image-con">{svgComp}</div>
      <div className="cards-card__text-con">
        <h4>{heading}</h4>
        <p>{text}</p>
      </div>
    </div>
  );
};

const Cards = () => {
  return (
    <section className="kitchen-cards__wrapper">
      <div className="kitchen-cards__container">
        <Card
          heading="Multi-Platform Order Tracking"
          text="Simplify your order management process by connecting with various food delivery platforms. Track incoming orders from platforms like Doordash, Uber Eats, SkipTheDishes, and more, all within a single interface. Streamline order processing, reduce errors, and provide timely updates to your customers."
          svgComp={<Multi />}
        />
        <Card
          heading="Integration with Delivery Platforms"
          text="Seamlessly integrate your food business with multiple delivery platforms through Skibble Kitchens. Connect with your preferred media, manage settings, and streamline order fulfillment for a cohesive and efficient delivery experience."
          svgComp={<Delivery />}
        />
        <Card
          heading="Insights and Analytics"
          text="Gain valuable insights into your business performance through comprehensive analytics and reporting features. Track sales, monitor trends, and identify your most popular items. Utilize this data to optimize your menus, target customer preferences, and drive growth."
          svgComp={<Analytics />}
        />
      </div>
    </section>
  );
};

export default Cards;
