import { SyntheticEvent, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import "./Meets.scss";

const taglines = [
  {
    headline: "Build Meaningful Connections through Food",
    text: "Connect with other foodies over a meal at your local restaurant, bar, or cafe—Looking for new friends to hang out with, dates, quick coffee chats, or even goodwill? Start that conversation at a nearby table now!",
  },
  {
    headline: "Earn Rewards and Connect with Real People",
    text: "Increase your meet scores for being real and unlock rewards for expanding your social circle.",
  },
  {
    headline: "Amplify Your Dining Experiences",
    text: "Text reviews suck right? Share your meet dining experiences in photos and videos and let other foodies join you on the experience.",
  },
];

const Meets = () => {
  const [expanded, setExpanded] = useState<string | false>("panel-0");

  const handleChange =
    (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <section className="meets__wrapper">
      <div className="meets__container">
        <div className="meets__top">
          <div className="meets__top-left">
            <h3>
              Say Hello to
              <br />
              <span>
                Skibble Meets<sup>TM</sup>
              </span>
            </h3>
          </div>
          <div className="meets__top-right">
            Our innovative solution to help you connect over your shared love
            for food. Meet new people, reserve a table, and create unforgettable
            food memories.
          </div>
        </div>

        <div className="meets__bottom">
          <div className="meets__bottom-left">
            <img src="/images/meets_image.webp" alt="skibble-meets" />
          </div>
          <div className="meets__bottom-right">
            {taglines.map(({ headline, text }, id) => (
              <Accordion
                key={id}
                expanded={expanded === `panel-${id}`}
                onChange={handleChange(`panel-${id}`)}
              >
                <AccordionSummary
                  expandIcon={
                    expanded === `panel-${id}` ? <RemoveIcon /> : <AddIcon />
                  }
                  aria-controls={`panel-${id}a-content`}
                  id={`panel-${id}a-header`}
                >
                  <h3>{headline}</h3>
                </AccordionSummary>

                <AccordionDetails>
                  <p>{text}</p>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Meets;
