import ReactGA from 'react-ga';

export const getSiteAnalytics = () => {
    ReactGA.pageview(window.location.pathname)
}

// const getNumberOfClicksOnAndroid = () => {
//     ReactGA.event({
//         category: 
//     })
// }