import { FormEvent, useState } from "react";
import { ClipLoader } from "react-spinners";
import { doc, getDoc, setDoc } from "firebase/firestore";

import Arrow from "../../../Components/Icons/Arrow";

import { database } from "../../../Firebase/firebase";

import "./Subscribe.scss";

const Subscribe = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (email === "") {
      alert("Please enter a valid email");
      return;
    }

    if (!email.includes("@")) {
      alert("Please enter a valid email");
      return;
    }

    setLoading(true);
    setError(false);

    try {
      const docRef = doc(database, "NewsLetter", email);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        //doc exists
      } else {
        await setDoc(doc(database, "NewsLetter", email), {
          email,
        });
      }
      setIsSubmitted(true);
      setEmail("");
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="home-subscribe__wrapper">
      <div className="home-subscribe__cover">
        <div className="home-subscribe__container">
          <div className="home-subscribe__text-con">
            <h3>
              Subscribe to <br /> our newsletter!
            </h3>
            <p>
              From recipe ideas to trending food news, <br />
              stay in the loop and never miss a bite! <br />
              Sign up now and join the foodies community.
            </p>
            {isSubmitted ? (
              <p>Thanks for subscribing</p>
            ) : (
              <form
                className="home-subscribe__form-con"
                onSubmit={handleSubmit}
              >
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  placeholder="Email"
                  required
                />
                <button disabled={loading}>
                  {loading ? (
                    <ClipLoader color="white" size={10} />
                  ) : (
                    <>
                      Subscribe <Arrow />
                    </>
                  )}
                </button>
              </form>
            )}
            {error && (
              <h3 className="error-text">
                An error occurred. Please try again
              </h3>
            )}
          </div>
          <div className="home-subscribe__image-con">
            <img src="/images/home_subscribe_720w.webp" alt="skibble2.0" />
          </div>
          <div className="home-subscribe__image-con-mobile">
            <img src="/images/home_subscribe_320w.webp" alt="skibble2.0" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Subscribe;
