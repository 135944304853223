
const RecipeIcon = () => {
  return (
    <svg
      width="52"
      height="52"
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M47.6666 36.27V10.1183C47.6666 7.51834 45.5433 5.59 42.965 5.80667H42.835C38.285 6.19667 31.3733 8.515 27.5166 10.9417L27.1483 11.18C26.52 11.57 25.48 11.57 24.8516 11.18L24.31 10.855C20.4533 8.45 13.5633 6.15334 9.01331 5.785C6.43498 5.56834 4.33331 7.51834 4.33331 10.0967V36.27C4.33331 38.35 6.02331 40.3 8.10331 40.56L8.73165 40.6467C13.4333 41.275 20.6916 43.6583 24.8516 45.9333L24.9383 45.9767C25.5233 46.3017 26.455 46.3017 27.0183 45.9767C31.1783 43.68 38.4583 41.275 43.1816 40.6467L43.8966 40.56C45.9766 40.3 47.6666 38.35 47.6666 36.27Z"
        stroke="#233748"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26 11.895V44.395"
        stroke="#233748"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.7917 18.395H11.9167"
        stroke="#233748"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.4167 24.895H11.9167"
        stroke="#233748"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RecipeIcon;
