import "./Simplify.scss";

const Simplify = () => {
  return (
    <section className="kitchen-simplify__wrapper">
      <div className="kitchen-simplify__container">
        <p>
          Empower your food business with Skibble Kitchen Dashboard—a
          comprehensive solution for seamless operations, menu management, order
          tracking, and customer relationships.
        </p>
        <p>
          Simplify your culinary business management and integrate with multiple
          delivery platforms for your food business from ghost kitchens to
          restaurants, cafes, food joints, and trucks.
        </p>
      </div>
    </section>
  );
};

export default Simplify;
